import Form from '@/found/components/form';
import request from '@/found/utils/request';

export default {
  extends: Form,
  data() {
    return {
      formFunctionCode: 'fee_pool_adjust_form',
      customerName: '',
      poolTypeList: [],
    };
  },
  methods: {
    // 设置rule
    setRule(v) {
      const item = v;
      if (item.field === 'customerName') {
        item.restful = '/mdm/mdmCustomerMsgController/findCustomerSelectList';
        item.restfulParams = {
          enableStatus: '009',
        };
        item.headers = { functionCode: 'select-customer' };
        item.props = {
          ...item.props,
          remoteParams: 'customerName',
          remote: true,
          filterable: true,
        };
        item.optionsKey = {
          value: 'customerCode',
          label: 'customerName',
        };
        item.on = {
          ...item.on,
          getLabel: (e) => {
            this.customerName = e;
          },
          change: (e) => {
            this.setValue({
              customerCode: e,
            });
          },
        };
      }
      if (item.field === 'goodsProductLevelCode') {
        item.restful = '/mdm/mdmProductLevelController/productLevelSelectList';
        item.headers = { functionCode: 'select-product-level' };
        item.on = {
          ...item.on,
          change: (val) => {
            this.getRule('goodsProductCode').restfulParams = {
              allUnderThisProductLevelCode: val,
            };
            this.setValue({
              goodsProductCode: '',
            });
          },
        };
        item.props = {
          ...item.props,
          remoteParams: 'productLevelName',
          remote: true,
          filterable: true,
        };
        item.optionsKey = {
          value: 'productLevelCode',
          label: 'productLevelName',
        };
      }
      if (item.field === 'goodsProductCode') {
        item.restful = '/mdm/mdmProductController/productSelectList';
        item.headers = { functionCode: 'select-goods' };
        item.props = {
          ...item.props,
          remoteParams: 'productName',
          remote: true,
          filterable: true,
        };
        item.refresh = true;
        item.restfulParams = {
          allUnderThisProductLevelCode: '',
        };
        item.optionsKey = {
          value: 'productCode',
          label: 'productName',
        };
      }
      return item;
    },
    // 表单渲染完成后回调
    formComplete() {
      this.disabled(true, 'customerCode');
      this.getSelectData();
    },
    // 获取下拉框数据
    getSelectData() {
      this.hiddenFields(true, ['goodsProductLevelCode', 'goodsProductCode']);
      request
        .get('/fee/feePoolController/getFeePoolPageConfig', { configCode: 'fee_pool_page_list' })
        .then((res) => {
          if (res.success) {
            let {
              accountOperationTypeList,
              adjustOperationTypeList,
              poolGroupList,
              poolTypeList,
              useTypeList,
              unfreezeOperationTypeList,
              freezeOperationTypeList,
            } = res.result;
            // 头部上账打开操作类型
            accountOperationTypeList = accountOperationTypeList.map((item) => ({
              ...item,
              label: item.text,
              value: item.value,
            }));
            // 行按钮打开操作类型
            adjustOperationTypeList = adjustOperationTypeList.map((item) => ({
              ...item,
              label: item.text,
              value: item.value,
            }));
            // 行按钮解冻操作类型
            unfreezeOperationTypeList = unfreezeOperationTypeList.map((item) => ({
              ...item,
              label: item.text,
              value: item.value,
            }));
            // 行按钮冻结操作类型
            freezeOperationTypeList = freezeOperationTypeList.map((item) => ({
              ...item,
              label: item.text,
              value: item.value,
            }));
            // 费用池分组
            poolGroupList = poolGroupList.map((item) => ({
              ...item,
              label: item.text,
              value: item.value,
            }));
            // 费用池类型
            poolTypeList = poolTypeList.map((item) => ({
              ...item,
              label: item.text,
              value: item.value,
            }));
            this.poolTypeList = poolTypeList;
            // 费用用途
            useTypeList = useTypeList.map((item) => ({
              ...item,
              label: item.text,
              value: item.value,
            }));
            // 操作类型处理
            const operationType = this.getRule('operationType');
            if (this.formConfig.code === 'make_ account') {
              operationType.options = accountOperationTypeList;
            } else if (this.formConfig.code === 'adjustment') {
              operationType.options = adjustOperationTypeList;
            } else if (this.formConfig.code === 'dms_thaw') {
              operationType.options = unfreezeOperationTypeList;
            } else if (this.formConfig.code === 'dms_frozen') {
              operationType.options = freezeOperationTypeList;
            }
            this.dealSelect('operationType');
            // 费用池分组处理
            const poolGroup = this.getRule('poolGroup');
            poolGroup.options = poolGroupList;
            this.dealSelect('poolGroup');
            // 费用池类型处理
            const poolType = this.getRule('poolType');
            const payType = this.getRule('payType');
            poolType.options = poolTypeList;
            this.dealSelect('poolType');
            poolType.on = {
              ...poolType.on,
              change: (e) => this.poolTypeChange(e),
            };
            // 费用用途
            const useType = this.getRule('useType');
            useType.options = useTypeList;
            this.dealSelect('useType');
            this.adjustment();
          }
        });
    },
    // 调整打开
    adjustment() {
      if (this.formConfig.code !== 'make_ account') {
        this.disabled(true, [
          'customerCode',
          'customerName',
          'poolGroup',
          'poolType',
          'payType',
          'useType',
          'goodsProductLevelCode',
          'goodsProductLevelCode',
          'goodsProductCode',
        ]);
        request
          .get('/fee/feePoolController/queryByPoolCode', { poolCode: this.formConfig.row.poolCode })
          .then((result) => {
            if (result.success) {
              const res = result;
              delete res.result.operationType;
              res.result.goodsProductCode = res.result.goodsProductCode
                ? res.result.goodsProductCode.split(',')
                : [];
              this.setValue(res.result);
              this.poolTypeChange(res.result.poolType);
              this.setValue({
                amount: '',
                remarks: '',
                fileList: [],
              });
            }
          });
      }
    },
    // 费用池类型change事件
    poolTypeChange(e) {
      if (!e) {
        this.setValue({
          payType: '',
        });
        this.disabled(false, 'payType');
        const payType = this.getRule('payType');
        payType.options = [];
        this.hiddenFields(true, ['goodsProductLevelCode', 'goodsProductCode']);
        return;
      }
      // eslint-disable-next-line no-shadow
      const itemObj = this.poolTypeList.find((item) => item.value === e);
      const payType = this.getRule('payType');
      payType.options = itemObj.payTypeList.map((item) => ({
        ...item,
        label: item.text,
      }));
      this.dealSelect('payType');
      if (e === 'goods') {
        this.hiddenFields(false, ['goodsProductLevelCode', 'goodsProductCode']);
      } else {
        this.hiddenFields(true, ['goodsProductLevelCode', 'goodsProductCode']);
      }
    },
    // 判断下拉框如果只有一个选项，默认选中并禁用
    dealSelect(field) {
      const fieldOption = this.getRule(field).options || [];
      if (fieldOption.length === 1) {
        this.setValue({
          [field]: fieldOption[0].value,
        });
        this.disabled(true, [field]);
        if (field === 'poolType') {
          this.poolTypeChange(fieldOption[0].value);
        }
      }
    },
    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        let url = '/fee/feePoolController/adjust';
        const params = {
          ...formData,
          goodsProductCode: Array.isArray(formData.goodsProductCode)
            ? formData.goodsProductCode.join(',')
            : '',
          customerName: this.customerName,
        };

        if (this.formConfig.code !== 'make_ account') {
          url = '/fee/feePoolController/adjustByPoolCode';
          params.poolCode = this.formConfig.row.poolCode;
        }
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
